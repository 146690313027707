<template>
  <div>
    <a-page-header>
      <template slot="footer">
        <a-tabs v-model="current">
          <a-tab-pane key="BASIS" tab="基本信息" />
          <a-tab-pane v-if="$auth('skills_phase_detail')" key="PHASE" tab="阶段信息" />
        </a-tabs>
      </template>

      <h-header
        :dataSource="dataSource"
        @update="handleUpdate"
        @switch="handleSwitchStatus"
        @remove="handleRemove"
      ></h-header>
    </a-page-header>

    <a-card :bodyStyle="{ padding: 0 }" style="margin-top:40px">
      <div v-if="current === 'PHASE' && $auth('skills_phase_add')" slot="extra">
        <a-button
          type="primary"
          shape="round"
          @click="$router.push(`/skill-management/skill/${dataSource.id}/phase/create`)"
        >
          <a-icon type="plus" /><span>新增阶段</span>
        </a-button>
      </div>

      <basis-info v-if="current === 'BASIS'" :dataSource="dataSource"></basis-info>
      <phase-info
        v-if="current === 'PHASE'"
        :dataSource="dataSource"
        @remove="index => dataSource.phaseList.splice(index, 1)"
      ></phase-info>
    </a-card>
  </div>
</template>
<script>
import events from '@/components/MultiTab/events'
import * as skillApi from '@/api/skill'
import hHeader from './components/Header'
import BasisInfo from './components/BasisInfo'
import PhaseInfo from './components/PhaseInfo'

export default {
  name: 'SkillManagementSkillDetail',
  components: {
    hHeader,
    BasisInfo,
    PhaseInfo
  },
  data() {
    return {
      current: 'BASIS',
      dataSource: {
        coverUrl: '',
        description: '',
        field: {
          code: '',
          id: undefined,
          introduction: '',
          isShow: true,
          level: '',
          name: '',
          sequence: 0,
          skillCount: 0,
          url: '',
          parent: {}
        },
        goal: '',
        introduction: '',
        isBaseSkill: true,
        level: 0,
        name: '',
        prerequisite: '',
        suitableAge: 0,
        trainingPreparationList: [],
        type: '',
        videoUrl: ''
      }
    }
  },
  activated() {
    const {
      $router,
      $route: {
        fullPath,
        query: { t },
        params: { id }
      },
      dataSource,
      ready,
      initData
    } = this

    if (t) {
      const index = fullPath.indexOf('?')
      const path = index > -1 ? fullPath.substring(0, index) : fullPath

      this.current = 'PHASE'
      $router.replace(path)
    }

    if (parseInt(id) !== dataSource.id) {
      this.current = 'BASIS'
    }

    if (ready) {
      initData()
    }
  },
  mounted() {
    const { $nextTick, initData } = this

    $nextTick(() => {
      this.ready = true
    })

    initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this

      skillApi.detail(id).then(res => {
        this.dataSource = res.data
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    },
    handleUpdate() {
      const {
        $router,
        dataSource: { id }
      } = this

      $router.push({
        path: `/skill-management/skill/edit/${id}`
      })
    },
    handleSwitchStatus() {
      const {
        $confirm,
        $notification,
        $info,
        initData,
        dataSource: { id, status }
      } = this

      const nextStatusMap = {
        CREATED: {
          title: '确定发布',
          content: '确定发布当前技能包'
        },
        PUBLISHED: {
          title: '确定禁用',
          content: '确定禁用当前技能包'
        },
        DISABLED: {
          title: '确定发布',
          content: '确定发布当前技能包'
        }
      }
      const nextStatus = nextStatusMap[status]

      const confirm = $confirm({
        ...nextStatus,
        onOk: () => {
          return skillApi
            .switchStatus(id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              initData()
            })
            .catch(err => {
              confirm.destroy()
              $info({
                title: '操作失败',
                content: err.msg
              })
            })
        }
      })
    },
    handleRemove() {
      const {
        $confirm,
        $notification,
        $info,
        dataSource: { id }
      } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除当前技能包',
        onOk: () => {
          return skillApi
            .remove(id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              close()
            })
            .catch(err => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-page-header {
  margin: -24px;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin-right: 24px;
}
/deep/ .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
}
</style>
