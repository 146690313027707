<template>
  <div>
    <a-card :bordered="false" :headStyle="{ padding: 0 }" :bodyStyle="{ padding: '16px 40px' }">
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>技能描述</span>
          </a-space>
        </div>
      </div>

      <p v-if="dataSource.description" class="pre">{{ dataSource.description }}</p>
      <a-empty v-else></a-empty>
    </a-card>

    <a-card :bordered="false" :headStyle="{ padding: 0 }" :bodyStyle="{ padding: '16px 40px' }">
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>训练目标</span>
          </a-space>
        </div>
      </div>

      <p v-if="dataSource.goal" class="pre">{{ dataSource.goal }}</p>
      <a-empty v-else></a-empty>
    </a-card>

    <a-card :bordered="false" :headStyle="{ padding: 0 }" :bodyStyle="{ padding: '16px 40px' }">
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>训练概述</span>
          </a-space>
        </div>
      </div>

      <p v-if="dataSource.introduction" class="pre">{{ dataSource.introduction }}</p>
      <a-empty v-else></a-empty>
    </a-card>

    <a-card :bordered="false" :headStyle="{ padding: 0 }" :bodyStyle="{ padding: '16px 40px' }">
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>先备技能</span>
          </a-space>
        </div>
      </div>

      <p v-if="dataSource.prerequisite" class="pre">{{ dataSource.prerequisite }}</p>
      <a-empty v-else></a-empty>
    </a-card>

    <a-card :bordered="false" :headStyle="{ padding: 0 }" :bodyStyle="{ padding: '16px 40px' }">
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>学前准备</span>
          </a-space>
        </div>
      </div>

      <a-empty v-if="dataSource.trainingPreparationList.length === 0"></a-empty>
      <div
        v-else
        v-for="(preparationInfo, index) in dataSource.trainingPreparationList"
        :key="index"
        style="margin-bottom:24px"
      >
        <a-space direction="vertical">
          <div v-for="(resourceInfo, rIndex) in preparationInfo.resourceList" :key="rIndex">
            <a
              :href="resourceInfo.url"
              target="blank_"
              style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
            >
              <a-icon type="file" style="margin-right:8px" />{{ resourceInfo.name }}
            </a>
          </div>

          <p v-if="preparationInfo.introduction" class="pre text-color-secondary">{{ preparationInfo.introduction }}</p>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          coverUrl: '',
          description: '',
          field: {
            code: '',
            id: undefined,
            introduction: '',
            isShow: true,
            level: '',
            name: '',
            sequence: 0,
            skillCount: 0,
            url: '',
            parent: {}
          },
          goal: '',
          introduction: '',
          isBaseSkill: true,
          level: 0,
          name: '',
          prerequisite: '',
          suitableAge: 0,
          trainingPreparationList: [],
          type: '',
          videoUrl: ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
p.pre {
  line-height: 2;
}
</style>
