<template>
  <a-card>
    <a-row type="flex" align="middle" :gutter="24">
      <a-col flex="0 1 80px">
        <a-avatar :size="80" shape="square" :src="dataSource.coverUrl"></a-avatar>
      </a-col>

      <a-col
        flex="1"
        style="height:80px;overflow:hidden;display:flex;flex-direction:column;justify-content:space-between"
      >
        <a-row type="flex" align="middle">
          <a-col style="display:flex;align-items:center">
            <span class="heading-color" style="font-size:20px">{{ dataSource.name }}</span>

            <a-tag
              v-if="dataSource.isBaseSkill"
              style="margin-left:8px;"
              :style="{
                color: '#2795CC',
                background: '#E1F5FF'
              }"
            >
              基础
            </a-tag>
            <a-tag
              v-if="dataSource.type !== 'NONE'"
              style="margin-left:8px;"
              :style="{
                color: '#EF5C26',
                background: '#FFE9E1'
              }"
            >
              {{ dataSource.type | SkillTypeText() }}
            </a-tag>

            <span style="margin-left:8px;">
              <a-badge :status="dataSource.status | SkillStatusBadge()" />{{ dataSource.status | SkillStatusText() }}
            </span>
          </a-col>
        </a-row>

        <a-row type="flex" align="middle">
          <a-col>
            <span>
              领域:<span style="margin-left:8px">{{ dataSource.field.parent.name }} > {{ dataSource.field.name }}</span>
            </span>
            <a-divider type="vertical"></a-divider>
            <span>
              等级:<span style="margin-left:8px">{{ dataSource.level | SkillLevelText() }}</span>
            </span>
            <a-divider type="vertical"></a-divider>
            <span>
              适合年龄:<span style="margin-left:8px">{{ dataSource.suitableAge | SuitableAgeText() }}</span>
            </span>
          </a-col>

          <a-col style="margin-left:auto">
            <a-space :size="16">
              <a-button v-if="$auth('skills_update')" type="link" @click="$emit('update')"
                ><a-icon type="edit" />编辑技能</a-button
              >
              <a-button
                v-if="$auth('skills_update_status')"
                type="link"
                :class="dataSource.status === 'PUBLISHED' ? 'default-color' : ''"
                @click="$emit('switch')"
              >
                <a-icon :type="dataSource.status === 'PUBLISHED' ? 'stop' : 'rocket'" />
                {{ dataSource.status | SkillNextStatusText() }}技能
              </a-button>
              <a-button v-if="$auth('skills_delete')" type="link" class="error-color" @click="$emit('remove')">
                <a-icon type="delete" />删除技能
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          coverUrl: '',
          description: '',
          field: {
            code: '',
            id: undefined,
            introduction: '',
            isShow: true,
            level: '',
            name: '',
            sequence: 0,
            skillCount: 0,
            url: '',
            parent: {}
          },
          goal: '',
          introduction: '',
          isBaseSkill: true,
          level: 0,
          name: '',
          prerequisite: '',
          suitableAge: 0,
          trainingPreparationList: [],
          type: '',
          videoUrl: ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
