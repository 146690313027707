var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',[_c('a-row',{attrs:{"type":"flex","align":"middle","gutter":24}},[_c('a-col',{attrs:{"flex":"0 1 80px"}},[_c('a-avatar',{attrs:{"size":80,"shape":"square","src":_vm.dataSource.coverUrl}})],1),_c('a-col',{staticStyle:{"height":"80px","overflow":"hidden","display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"flex":"1"}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"heading-color",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.dataSource.name))]),(_vm.dataSource.isBaseSkill)?_c('a-tag',{staticStyle:{"margin-left":"8px"},style:({
              color: '#2795CC',
              background: '#E1F5FF'
            })},[_vm._v(" 基础 ")]):_vm._e(),(_vm.dataSource.type !== 'NONE')?_c('a-tag',{staticStyle:{"margin-left":"8px"},style:({
              color: '#EF5C26',
              background: '#FFE9E1'
            })},[_vm._v(" "+_vm._s(_vm._f("SkillTypeText")(_vm.dataSource.type))+" ")]):_vm._e(),_c('span',{staticStyle:{"margin-left":"8px"}},[_c('a-badge',{attrs:{"status":_vm._f("SkillStatusBadge")(_vm.dataSource.status)}}),_vm._v(_vm._s(_vm._f("SkillStatusText")(_vm.dataSource.status))+" ")],1)],1)],1),_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',[_c('span',[_vm._v(" 领域:"),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.dataSource.field.parent.name)+" > "+_vm._s(_vm.dataSource.field.name))])]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',[_vm._v(" 等级:"),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm._f("SkillLevelText")(_vm.dataSource.level)))])]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',[_vm._v(" 适合年龄:"),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm._f("SuitableAgeText")(_vm.dataSource.suitableAge)))])])],1),_c('a-col',{staticStyle:{"margin-left":"auto"}},[_c('a-space',{attrs:{"size":16}},[(_vm.$auth('skills_update'))?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('update')}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v("编辑技能")],1):_vm._e(),(_vm.$auth('skills_update_status'))?_c('a-button',{class:_vm.dataSource.status === 'PUBLISHED' ? 'default-color' : '',attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('switch')}}},[_c('a-icon',{attrs:{"type":_vm.dataSource.status === 'PUBLISHED' ? 'stop' : 'rocket'}}),_vm._v(" "+_vm._s(_vm._f("SkillNextStatusText")(_vm.dataSource.status))+"技能 ")],1):_vm._e(),(_vm.$auth('skills_delete'))?_c('a-button',{staticClass:"error-color",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v("删除技能 ")],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }