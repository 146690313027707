<template>
  <div>
    <a-card
      v-for="(phaseInfo, index) in dataSource.phaseList"
      :key="index"
      :headStyle="{ paddingLeft: 0 }"
      :bodyStyle="{ padding: 0 }"
    >
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>第{{ convertToChineseNumber(index + 1) }}阶段</span>
          </a-space>
        </div>
      </div>

      <div slot="extra">
        <a-space>
          <a-button v-if="$auth('skills_phase_update')" type="link" @click="handleUpdate(phaseInfo)">
            <a-icon type="edit" />编辑
          </a-button>
          <a-button
            v-if="$auth('skills_phase_delete')"
            type="link"
            @click="handleRemove(phaseInfo, index)"
            class="error-color"
          >
            <a-icon type="delete" />删除
          </a-button>
        </a-space>
      </div>

      <a-form-model v-bind="$formItemLayout4Detail">
        <a-form-model-item label="阶段名称">
          <span>{{ phaseInfo.name }}</span>
        </a-form-model-item>

        <a-form-model-item label="阶段目标">
          <p class="pre" style="margin-top:0">{{ phaseInfo.goal || '-' }}</p>
        </a-form-model-item>

        <a-form-model-item label="最低回合数">
          <span>
            <span class="primary-color" style="margin-right:8px">{{ phaseInfo.minimumRounds }}</span>
            <span>回合</span>
          </span>
        </a-form-model-item>

        <a-form-model-item label="记录方式">
          <span>{{ phaseInfo.recordingMethod | RecordingMethodText() }}</span>
        </a-form-model-item>

        <a-form-model-item label="达标标准">
          <a-row type="flex" align="middle">
            <a-col>至少</a-col>
            <a-col>
              <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.materialNumber }}</span>
            </a-col>
            <a-col>个素材，每个正确率连续</a-col>
            <a-col>
              <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.day }}</span>
            </a-col>
            <a-col>天达到</a-col>
            <template v-if="phaseInfo.recordingMethod !== 'NUMBER_SUCCESS'">
              <a-col>
                <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.successRate }}</span>
              </a-col>
              <a-col>%</a-col>
            </template>
            <template v-else>
              <a-col>
                <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.frequency }}</span>
              </a-col>
              <a-col>次，每</a-col>
              <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.minutes }}</span>
              <a-col>分，每</a-col>
              <a-col>
                <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.seconds }}</span>
              </a-col>
              <a-col>秒</a-col>
            </template>
          </a-row>
        </a-form-model-item>

        <a-form-model-item v-if="phaseInfo.recordingMethod === 'DURATION'" label="达标时长">
          <a-row type="flex" align="middle">
            <a-col>
              <span class="primary-color" style="margin-right:16px;">{{ phaseInfo.minutes }}</span>
            </a-col>
            <a-col>分钟</a-col>
            <a-col>
              <span class="primary-color" style="margin:0 16px;">{{ phaseInfo.seconds }}</span>
            </a-col>
            <a-col>秒</a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="训练素材">
          <div style="display:flex;align-items:center;padding-top:4px">
            <a-tag v-for="(material, mIndex) in phaseInfo.material" :key="mIndex" class="tag-bg">{{
              material.materialName
            }}</a-tag>
          </div>
        </a-form-model-item>

        <a-form-model-item label="素材说明">
          <p class="pre" style="margin-top:0">{{ phaseInfo.materialDescription || '-' }}</p>
        </a-form-model-item>

        <a-form-model-item label="学前准备">
          <div
            v-for="(preparationInfo, pIndex) in phaseInfo.trainingPreparationList"
            :key="pIndex"
            style="margin-bottom:16px"
          >
            <div v-for="(resourceInfo, rIndex) in preparationInfo.resourceList" :key="rIndex">
              <a
                :href="resourceInfo.url"
                target="blank_"
                style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
              >
                <a-icon type="file" style="margin-right:8px" />{{ resourceInfo.name }}
              </a>
            </div>

            <p v-if="preparationInfo.introduction" class="pre text-color-secondary">
              {{ preparationInfo.introduction }}
            </p>
          </div>
        </a-form-model-item>
        <a-form-model-item label="关联阶段" v-if="phaseInfo.phaseId">
          <p class="pre" style="margin-top:0; font-weight:600;">{{ phaseInfo.phaseName || '-' }}</p>
          <p class="pre" style="margin-top:0">所属领域：{{ phaseInfo.fieldName || '-' }}</p>
          <p class="pre" style="margin-top:0">阶段介绍：{{ phaseInfo.introduction || '-' }}</p>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import * as skillApi from '@/api/skill'
import { convertToChineseNumber } from '@/utils/util'

export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          coverUrl: '',
          description: '',
          field: {
            code: '',
            id: undefined,
            introduction: '',
            isShow: true,
            level: '',
            name: '',
            sequence: 0,
            skillCount: 0,
            url: '',
            parent: {}
          },
          goal: '',
          introduction: '',
          isBaseSkill: true,
          level: 0,
          name: '',
          prerequisite: '',
          suitableAge: 0,
          trainingPreparationList: [],
          type: '',
          videoUrl: ''
        }
      }
    }
  },
  methods: {
    convertToChineseNumber,
    handleUpdate({ id }) {
      const {
        $router,
        $route: {
          params: { id: skillId }
        }
      } = this

      $router.push({
        path: `/skill-management/skill/${skillId}/phase/edit/${id}`
      })
    },
    handleRemove({ id }, index) {
      const {
        $confirm,
        $notification,
        $info,
        $route: {
          params: { id: skillId }
        }
      } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除当前阶段',
        onOk: () => {
          return skillApi
            .removePhase({ skillId, id })
            .then(() => {
              $notification['success']({ message: '操作成功' })
              this.$emit('remove', index)
            })
            .catch(err => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-control-wrapper {
  padding-left: 16px;
}

/deep/ .ant-card-bordered {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
}
/deep/ .ant-form-item {
  margin-bottom: 24px;
}
/deep/ .ant-form-item-label,
/deep/ .ant-form-item-control,
p.pre {
  line-height: 2;
}
</style>
